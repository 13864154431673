import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"
import Layout from "../components/layout"

const NotFoundPage = () => (
  <StaticQuery
    query={graphql`{
  heroImage: file(relativePath: {eq: "404-keyboard.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`}
    render={data => (
      <Layout>
        <header
          id="notfoundpage"
          className="pb-5 position-relative overflow-hidden"
        >
          <div className="container ">
            <GatsbyImage
              image={data.heroImage.childImageSharp.gatsbyImageData}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }} />
            <div className="dark-overlay" />
            <div className="row main-header align-items-center">
              <div className="col-md-10 col-lg-7">
                <h6 className="text-primary text-uppercase">OOOOPS...</h6>
                <h1 className="text-white pb-2">
                  404 Error... You are on a non existing page!
                </h1>
                <Link to="/" className="btn btn-info text-uppercase">
                  Get Back Home
                </Link>
              </div>
            </div>
          </div>
        </header>
      </Layout>
    )}
  />
)

export default NotFoundPage
